<template>
    <div class="home">
        <div class="flex show main jsb" id="d0">
            <div class="left">
                <div class="lImg" @click="changeSub">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="rImg" @click="changeAdd">
                    <i class="el-icon-arrow-right"></i>
                </div>
                <!-- <img src="../../assets/image/icon/left.png" class="lImg" alt="" @click="changeSub" />
          <img src="../../assets/image/icon/right.png" class="rImg" alt="" @click="changeAdd" /> -->
                <el-card>
                    <div class="img flex jsc alc">
                        <div id="viewer" style="overflow: hidden" :key="detail.panoramaUrl"></div>
                    </div>

                    <div class="flex jsb left-footer">
                        <div style="width: 42.5%">
                            <span v-html="detail.imageTitle" dd="请输入大类-对应类别门或纲" v-if="$i18n.locale != 'en-us'"></span>
                            <span v-html="detail.imageTitle" dd="请输入大类" v-else></span>
                        </div>
                        <div style="width: 15%; text-align: center">
                            <span>
                                {{ infoAct + 1 }}/ {{ detail.panoramaUrl && detail.panoramaUrl.split('&').length }}
                                <span v-if="$i18n.locale != 'en-us'">张</span>
                                <span v-else>page</span>
                            </span>
                        </div>
                        <!-- <favorite :topicId="$route.query.id" :type="1"></favorite> -->
                        <div class="footer-msg" style="width: 42.5%">
                            <!-- <div @click="delFavorite">
                  <i class="el-icon-star-off"></i>
                  <span v-if="$i18n.locale != 'en-us'">收藏</span>
                  <span v-else>Collection</span>
                  <span>{{ 12 }}</span>
                </div> -->
                            <favorite :topicId="$route.query.id" :type="1"></favorite>
                            <!-- <div>
                  <i class="el-icon-chat-dot-round"></i>
                  <span v-if="$i18n.locale != 'en-us'">查看评论</span>
                  <span v-else>Comments</span>
                </div> -->
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="right flex jsb wrap">
                <div class="title" style="padding: 20px 20px 0px 20px">
                    <span v-if="$i18n.locale != 'en-us'">相似推荐</span>
                    <span v-else>Similar to recommend</span>
                </div>
                <el-card>

                    <div class="infoList flex wrap jsb">
                        <div v-for="(item, index) in allList">
                            <img :class="{ imgAct: allAct == index }" :src="item.thumbnailUrl" alt="" :key="index"
                                @click="goInfo(item)" />
                        </div>
                        <div v-if="allList.length == 0">暂无数据</div>

                    </div>
                </el-card>

                <el-button class="more" ref="more" type="primary" :loading="getflag" @click="getmore">{{ $i18n.locale !=
                        'en-us' ? '加载更多' : 'Load More'
                }}</el-button>
                <!-- <div class="more" ref="more">
            <p v-if="$i18n.locale != 'en-us'" @click="getmore">加载更多</p>
            <p v-else @click="getmore">Load More</p>
          </div> -->
            </div>
        </div>

        <div class="flex all">
            <div class="msg">
                <el-form ref="form" :model="detail" class="form" label-position="left" label-width="130px">
                    <div class="msg-list" id="d1">
                        <!-- 全景图ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single" id="d5">
                            <div class="title" style="background-color: #92f6d3">全景图</div>
                            <div class="flex wrap jsb" style="justify-content: start">
                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.panoramaUrl')"
                                    trigger="hover" :content="detail.panoramaUrl">
                                    <el-form-item slot="reference" :label="$t('details.panoramaUrl') + ':'"
                                        prop="panoramaUrl" style="width: 93%; word-break: break-all"
                                        label-width="130px">
                                        <span v-html="detail.panoramaUrl" dd="全景图地址"></span>
                                    </el-form-item>
                                </el-popover>

                                <el-form-item :label="$t('details.section') + ':'" prop="section" style="width: 40%">
                                    <span v-html="detail.section" dd="请输入剖面"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.reference') + ':'" prop="reference"
                                    style="width: 40%">
                                    <span v-html="detail.reference" dd="请输入文献"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.dataSource') + ':'" prop="dataSource"
                                    style="width: 40%">
                                    <span v-html="detail.dataSource" dd="请输入数据来源"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.scannerType') + ':'" prop="scannerTypeEn"
                                    style="width: 40%">
                                    <span v-html="detail.scannerTypeEn" dd="请输入扫描类型"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.resolution') + ':'" prop="resolution"
                                    style="width: 40%">
                                    <span v-html="detail.resolution" dd="请输入解析度"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.dataVolume') + ':'" prop="dataVolume"
                                    style="width: 40%">
                                    <span v-html="detail.dataVolume" dd="请输入数据量"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.dataFormat') + ':'" prop="dataFormat"
                                    style="width: 40%">
                                    <span v-html="detail.dataFormat" dd="请输入数据格式"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.description') + ':'" prop="description"
                                    style="width: 40%">
                                    <span v-html="detail.description" dd="请输入数据描述"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords" style="width: 40%">
                                    <span v-html="detail.keyWords" dd="请输入关键词"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.locality') + ':'" prop="locality" style="width: 50%">
                                    <span v-html="detail.locality" dd="拍摄地"></span>
                                </el-form-item>
                            </div>
                        </div>

                        <!-- 全景图EN -->
                        <div v-else class="single" id="d5">
                            <div class="title" style="background-color: #92f6d3">全景图</div>
                            <div class="flex wrap jsb" style="justify-content: start">

                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.panoramaUrl')"
                                    trigger="hover" :content="detail.panoramaUrl">
                                    <el-form-item slot="reference" :label="$t('details.panoramaUrl') + ':'"
                                        prop="panoramaUrl" style="width: 93%; word-break: break-all"
                                        label-width="130px">
                                        <span v-html="detail.panoramaUrl" dd="全景图地址"></span>
                                    </el-form-item>
                                </el-popover>

                                <el-form-item :label="$t('details.section') + ':'" prop="section" style="width: 40%">
                                    <span v-html="detail.section" dd="请输入剖面"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.reference') + ':'" prop="reference"
                                    style="width: 40%">
                                    <span v-html="detail.reference" dd="请输入文献"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.dataSource') + ':'" prop="dataSource"
                                    style="width: 40%">
                                    <span v-html="detail.dataSourceEn" dd="请输入数据来源"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.scannerType') + ':'" prop="scannerTypeEn"
                                    style="width: 40%">
                                    <span v-html="detail.scannerTypeEn" dd="请输入扫描类型"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.resolution') + ':'" prop="resolution"
                                    style="width: 40%">
                                    <span v-html="detail.resolutionEn" dd="请输入解析度"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.dataVolume') + ':'" prop="dataVolume"
                                    style="width: 40%">
                                    <span v-html="detail.dataVolume" dd="请输入数据量"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.dataFormat') + ':'" prop="dataFormat"
                                    style="width: 40%">
                                    <span v-html="detail.dataFormat" dd="请输入数据格式"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.description') + ':'" prop="description"
                                    style="width: 40%">
                                    <span v-html="detail.description" dd="请输入数据描述"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords" style="width: 40%">
                                    <span v-html="detail.keyWords" dd="请输入关键词"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.locality') + ':'" prop="locality" style="width: 50%">
                                    <span v-html="detail.localityEn" dd="拍摄地"></span>
                                </el-form-item>
                            </div>
                        </div>

                        <!-- 生物分类信息-ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single">
                            <div class="title">古生物分类信息</div>
                            <el-form-item v-for="item in all" :label="$t(`details.${item.title}`) + ':'"
                                prop="trivialName" :key="item.content" style="width: 100%">
                                <span v-html="item.content" dd="请输入大类"></span>
                            </el-form-item>

                            <el-form-item :label="$t('details.nomenclator') + ':'" prop="nomenclatorEn">
                                <span v-html="detail.nomenclatorEn" dd="请输入命名人-英文"></span>
                            </el-form-item>
                            <el-form-item :label="$t('details.defineTime') + ':'" prop="defineTime">
                                <span v-html="detail.defineTime" dd="确定时间"></span>
                            </el-form-item>
                            <el-form-item :label="$t('details.revisedSpecies') + ':'" prop="revisedSpecies">
                                <span v-html="detail.revisedSpecies" dd="请输入修订属种"></span>
                            </el-form-item>

                            <div style="width: 220px"></div>
                            <div style="width: 220px"></div>
                        </div>
                        <!-- 生物分类信息-EN -->
                        <div v-else class="single" id="d1">
                            <div class="title">TAXONOMY</div>
                            <el-form-item v-for="item in allEn" :label="$t(`details.${item.title}`) + ':'"
                                prop="trivialName" :key="item.content" style="width: 100%">
                                <span v-html="item.content" dd="请输入大类"></span>
                            </el-form-item>

                            <el-form-item :label="$t('details.defineTime') + ':'" prop="defineTime">
                                <span v-html="detail.defineTime" dd="确定时间"></span>
                            </el-form-item>
                            <el-form-item :label="$t('details.revisedSpecies') + ':'" prop="revisedSpecies">
                                <span v-html="detail.revisedSpeciesEn" dd="请输入修订属种-英文"></span>
                            </el-form-item>

                            <div style="width: 220px"></div>
                            <div style="width: 220px"></div>
                        </div>
                        <!-- 地质信息ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single" id="d2">
                            <div class="title" style="background-color: #92f6d3">地质信息</div>
                            <div class="flex wrap jsb" style="justify-content: start">
                                <div class="flex jsb agelist ">
                                    <div v-if="detail.era" v-html="detail.era" class="flex alc jsc"
                                        :style="{ background: detail.eraColor ? initcolor(detail.eraColor) : '#00b9e7' }">
                                    </div>
                                    <div v-if="detail.period" v-html="detail.period" class="flex alc jsc"
                                        :style="{ background: detail.periodColor ? initcolor(detail.periodColor) : '#85c86f' }">
                                    </div>
                                    <div v-if="detail.epoch" v-html="detail.epoch" class="flex alc jsc"
                                        :style="{ background: detail.epochColor ? initcolor(detail.epochColor) : '#8aaa78' }">
                                    </div>
                                </div>

                                <el-form-item :label="$t('details.ageFrom') + ':'" prop="ageFrom" style="width: 100%">
                                    <span v-html="detail.ageFrom + '~' + detail.ageTo" dd="请输入首现年龄/Ma"
                                        v-if="detail.ageFrom"></span>
                                    <span v-else></span>
                                </el-form-item>
                                <!-- <el-form-item :label="$t('details.ageTo') + ':'" prop="ageTo" style="width: 40%">
                    <span v-html="detail.ageTo" dd="请输入末现年龄/Ma"></span>
                  </el-form-item>
                  <el-form-item :label="$t('details.ageMid') + ':'" prop="ageMid" style="width: 40%">
                    <span v-html="detail.ageMid" dd="请输入平均年龄/Ma"></span>
                  </el-form-item> -->
                                <el-form-item :label="$t('details.section') + ':'" prop="section" style="width: 40%">
                                    <span v-html="detail.section" dd="请输入剖面"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.horizon') + ':'" prop="horizon" style="width: 40%">
                                    <span v-html="detail.horizon" dd="请输入层位"></span>
                                </el-form-item>
                                <!-- <MyTable></MyTable> -->

                                <!-- <div id="geoTimeLineContainer" style="width: 100%"></div> -->

                                <div style="width: 220px"></div>
                                <div style="width: 220px"></div>
                            </div>
                        </div>
                        <!-- 地质信息EN -->
                        <div v-else class="single" id="d2">
                            <div class="title" style="background-color: #92f6d3">LITHOSTRATIGRAPHY AGE</div>
                            <div class="flex wrap jsb " style="justify-content: start">

                                <div class="flex jsb agelist ">
                                    <div v-if="detail.era" v-html="detail.era" class="flex alc jsc"
                                        :style="{ background: detail.eraColor ? initcolor(detail.eraColor) : '#00b9e7' }">
                                    </div>
                                    <div v-if="detail.period" v-html="detail.period" class="flex alc jsc"
                                        :style="{ background: detail.periodColor ? initcolor(detail.periodColor) : '#85c86f' }">
                                    </div>
                                    <div v-if="detail.epoch" v-html="detail.epoch" class="flex alc jsc"
                                        :style="{ background: detail.epochColor ? initcolor(detail.epochColor) : '#8aaa78' }">
                                    </div>
                                </div>

                                <el-form-item :label="$t('details.ageFrom') + ':'" prop="ageFrom" label-width="160px"
                                    style="width: 100%">
                                    <span v-html="detail.ageFrom + '~' + detail.ageTo" dd="请输入首现年龄/Ma"
                                        v-if="detail.ageFrom"></span>
                                    <span v-else></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.section') + ':'" prop="section" style="width: 40%">
                                    <span v-html="detail.sectionEn" dd="请输入剖面-英文"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.horizon') + ':'" prop="horizon" style="width: 40%">
                                    <span v-html="detail.horizonEn" dd="请输入层位-英文"></span>
                                </el-form-item>
                                <!-- <MyTable></MyTable> -->
                                <!-- <div id="geoTimeLineContainer" style="width: 100%"></div> -->

                                <div style="width: 220px"></div>
                                <div style="width: 220px"></div>
                            </div>
                        </div>
                        <!-- 地理信息ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single" id="d3">
                            <div class="title" style="background-color: #90dcfe">地理信息</div>
                            <div class="flex wrap jsb" style="justify-content: start;height: 180px;">
                                <!-- <el-form-item :label="$t('details.locality') + ':'" prop="locality" style="width: 93%">
                    <span v-html="detail.locality" dd="请输入产地"></span>
                  </el-form-item> -->

                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.locality')"
                                    trigger="hover" :content="detail.locality">
                                    <el-form-item slot="reference" :label="$t('details.locality') + ':'" prop="locality"
                                        style="width: 93%; word-break: break-all" label-width="100px">
                                        <span v-html="detail.locality" dd="请输入产地"></span>
                                    </el-form-item>
                                </el-popover>

                                <el-form-item :label="$t('details.GPS') + ':'" prop="longitude" style="width: 100%"
                                    label-width="100px">
                                    <span
                                        v-html="detail.latitude && detail.longitude ? '(' + detail.latitude + ',' + detail.longitude + ')' : '(,)'"
                                        dd="请输入经度"></span>
                                </el-form-item>

                                <!-- <el-form-item :label="$t('details.longitude') + ':'" prop="longitude" style="width: 100%"
                    label-width="60px">
                    <span v-html="detail.longitude" dd="请输入经度"></span>
                  </el-form-item>
                  <el-form-item :label="$t('details.latitude') + ':'" prop="latitude" style="width: 100%"
                    label-width="60px">
                    <span v-html="detail.latitude" dd="请输入纬度"></span>
                  </el-form-item> -->
                                <el-form-item :label="$t('details.attitude') + ':'" prop="attitude" style="width: 100%"
                                    label-width="100px">
                                    <span v-html="detail.attitude" dd="请输入高程"></span>
                                </el-form-item>
                            </div>

                            <div class="amap-wrapper" v-if="detail.longitude"
                                @click="goamap(detail.longitude, detail.latitude)">
                                <el-amap class="amap-box" :vid="'amap-vue'"
                                    :lang="$i18n.locale != 'en-us' ? 'zh_cn' : 'en'"
                                    :center="[detail.longitude, detail.latitude]">
                                    <el-amap-marker :position="[detail.longitude, detail.latitude]"> </el-amap-marker>
                                </el-amap>
                            </div>
                        </div>
                        <!-- 地理信息EN -->
                        <div v-else class="single" id="d3">
                            <div class="title" style="background-color: #90dcfe">LOCATION</div>
                            <div class="flex wrap jsb" style="justify-content: start;height: 180px;">

                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.locality')"
                                    trigger="hover" :content="detail.localityEn">
                                    <el-form-item slot="reference" :label="$t('details.locality') + ':'" prop="locality"
                                        style="width: 93%; word-break: break-all" label-width="100px">
                                        <span v-html="detail.localityEn" dd="请输入产地"></span>
                                    </el-form-item>
                                </el-popover>

                                <el-form-item :label="$t('details.GPS') + ':'" prop="longitude" style="width: 100%"
                                    label-width="100px">
                                    <span
                                        v-html="detail.latitude && detail.longitude ? '(' + detail.latitude + ',' + detail.longitude + ')' : '(,)'"
                                        dd="请输入经度"></span>
                                </el-form-item>

                                <!-- <el-form-item :label="$t('details.longitude') + ':'" prop="longitude" style="width: 100%"
                    label-width="100px">
                    <span v-html="detail.longitude" dd="请输入经度"></span>
                  </el-form-item>
                  <el-form-item :label="$t('details.latitude') + ':'" prop="latitude" style="width: 100%"
                    label-width="100px">
                    <span v-html="detail.latitude" dd="请输入纬度"></span>
                  </el-form-item> -->
                                <el-form-item :label="$t('details.attitude') + ':'" prop="attitude" style="width: 100%"
                                    label-width="100px">
                                    <span v-html="detail.attitude" dd="请输入高程"></span>
                                </el-form-item>

                            </div>

                            <div class="amap-wrapper" v-if="detail.longitude"
                                @click="goamap(detail.longitude, detail.latitude)">
                                <el-amap class="amap-box" :vid="'amap-vue'"
                                    :lang="$i18n.locale != 'en-us' ? 'zh_cn' : 'en'"
                                    :center="[detail.longitude, detail.latitude]">
                                    <el-amap-marker :position="[detail.longitude, detail.latitude]"> </el-amap-marker>
                                </el-amap>
                            </div>
                        </div>
                        <!-- 化石标本信息ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single" id="d4">
                            <div class="title" style="background-color: #92f6d3">化石标本信息</div>
                            <div class="flex wrap jsb" style="justify-content: start">
                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.reference')"
                                    trigger="hover" :content="detail.reference">
                                    <el-form-item slot="reference" :label="$t('details.reference') + ':'"
                                        prop="reference" style="width: 93%; word-break: break-all">
                                        <span v-html="detail.reference" dd="请输入产地"></span>
                                    </el-form-item>
                                </el-popover>

                                <!-- <el-form-item :label="$t('details.reference') + ':'" prop="reference" style="width: 100%">
                    <span v-html="detail.reference" dd="请输入发表文献" v-if="$i18n.locale != 'en-us'"></span>
                  </el-form-item> -->
                                <el-form-item :label="$t('details.doi') + ':'" prop="doi" style="width: 40%">
                                    <span v-html="detail.doi" dd="请输入文献DOI"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.housedIn') + ':'" prop="dataSource"
                                    style="width: 50%">
                                    <span v-html="detail.dataSource" dd="请输入馆藏地"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.cabinet') + ':'" prop="cabinet" style="width: 40%">
                                    <span v-html="detail.cabinet" dd="请输入馆藏柜/厨"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.prefix') + ':'" prop="prefix" style="width: 40%">
                                    <span v-html="detail.prefix" dd="请输入标本号前缀"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.specimentsId') + ':'" prop="specimentsId"
                                    style="width: 40%">
                                    <!-- <span v-html="detail.specimentsId" dd="请输入标本登记号"></span> -->
                                    <a :href="'http://web.bbg.cn/#/spDetail?sampleId=' + detail.specimentsId"
                                        target="_blank">{{
                                                detail.specimentsId
                                        }}</a>
                                </el-form-item>
                                <el-form-item :label="$t('details.fieldNumber') + ':'" prop="fieldNumber"
                                    style="width: 40%">
                                    <span v-html="detail.fieldNumber" dd="请输入野外编号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.plate') + ':'" prop="plate" style="width: 40%">
                                    <span v-html="detail.plate" dd="请输入图版编号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.fig') + ':'" prop="fig" style="width: 40%">
                                    <span v-html="detail.fig" dd="请输入图片号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.funding') + ':'" prop="funding" style="width: 40%">
                                    <span v-html="detail.funding" dd="请输入资助来源"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.typeSpecimen') + ':'" prop="typeSpecimen"
                                    style="width: 40%">
                                    <span v-html="detail.typeSpecimen" dd="请输入模式情况"></span>
                                </el-form-item>
                                <div style="width: 220px"></div>
                            </div>
                        </div>
                        <!-- 化石标本信息EN -->
                        <div v-else class="single" id="d4">
                            <div class="title" style="background-color: #92f6d3">FOSSIL SPECIMEN INFO</div>
                            <div class="flex wrap jsb" style="justify-content: start">

                                <el-popover placement="top" :width="width * 0.93" :title="$t('details.reference')"
                                    trigger="hover" :content="detail.referenceEn">
                                    <el-form-item slot="reference" :label="$t('details.reference') + ':'"
                                        prop="reference" style="width: 93%; word-break: break-all">
                                        <span v-html="detail.referenceEn" dd="请输入产地"></span>
                                    </el-form-item>
                                </el-popover>
                                <el-form-item :label="$t('details.doi') + ':'" prop="doi" style="width: 40%">
                                    <span v-html="detail.doi" dd="请输入文献DOI"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.housedIn') + ':'" prop="dataSourceEn"
                                    style="width: 50%">
                                    <span v-html="detail.dataSourceEn" dd="请输入馆藏地"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.cabinet') + ':'" prop="cabinet" style="width: 40%">
                                    <span v-html="detail.cabinet" dd="请输入馆藏柜/厨"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.prefix') + ':'" prop="prefix" style="width: 40%">
                                    <span v-html="detail.prefix" dd="请输入标本号前缀"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.specimentsId') + ':'" prop="specimentsId"
                                    style="width: 40%">
                                    <a :href="'http://web.bbg.cn/#/spDetail?sampleId=' + detail.specimentsId"
                                        target="_blank">{{
                                                detail.specimentsId
                                        }}</a>
                                </el-form-item>
                                <el-form-item :label="$t('details.fieldNumber') + ':'" prop="fieldNumber"
                                    style="width: 40%">
                                    <span v-html="detail.fieldNumber" dd="请输入野外编号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.plate') + ':'" prop="plate" style="width: 40%">
                                    <span v-html="detail.plate" dd="请输入图版编号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.fig') + ':'" prop="fig" style="width: 40%">
                                    <span v-html="detail.fig" dd="请输入图片号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.funding') + ':'" prop="funding" style="width: 40%">
                                    <span v-html="detail.funding" dd="请输入资助来源"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.typeSpecimen') + ':'" prop="typeSpecimen"
                                    style="width: 40%">
                                    <span v-html="detail.typeSpecimen" dd="请输入模式情况"></span>
                                </el-form-item>

                                <div style="width: 220px"></div>
                            </div>
                        </div>
                        <!-- 其他详细信息ZH -->
                        <div v-if="$i18n.locale != 'en-us'" class="single" id="d5">
                            <div class="title">其他详细信息</div>
                            <div class="flex wrap jsb" style="justify-content: start">
                                <div class="flex wrap jsb" v-if="type == 2">
                                    <el-form-item :label="$t('details.txgs') + ':'" prop="picFormat"
                                        style="width: 100%">
                                        <span v-html="detail.picFormat" dd="请输入图像格式"></span>
                                    </el-form-item>
                                </div>
                                <!-- <el-popover placement="top" :width="width * 0.93" :title="$t('details.pictureName')" trigger="hover"
                    :content="detail.pictureName">
                    <el-form-item slot="reference" :label="$t('details.pictureName') + ':'" prop="pictureName"
                      style="width: 93%; word-break: break-all">
                      <span v-html="detail.pictureName" dd="请输入图文件名"></span>
                    </el-form-item>
                  </el-popover> -->

                                <el-form-item :label="$t('details.provider') + ':'" prop="provider" style="width: 40%">
                                    <span v-html="detail.provider" dd="请输入贡献者"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.input') + ':'" prop="input" style="width: 40%">
                                    <span v-html="detail.input" dd="请输入录入员"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.checkA') + ':'" prop="checkA" style="width: 40%">
                                    <span v-html="detail.checkA" dd="请输入校对员"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.deviceNameAndType') + ':'" prop="pictureName"
                                    style="width: 40%">
                                    <span v-html="detail.deviceNameAndType" dd="请输入设备名称与型号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.notes') + ':'" prop="notes" style="width: 40%">
                                    <span v-html="detail.notes" dd="请输入内容"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.dpi') + ':'" prop="dpi" v-if="type == 2"
                                    style="width: 40%">
                                    <span v-html="detail.dpi" dd="请输入分辨率"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords" v-if="type == 2"
                                    style="width: 40%">
                                    <span v-html="detail.keyWords" dd="请输入关键词"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.description') + ':'" prop="description"
                                    style="width: 50%">
                                    <span v-html="detail.description" dd="请输入描述"></span>
                                </el-form-item>
                            </div>
                        </div>
                        <!-- 其他详细信息EN -->
                        <div v-else class="single" id="d5">
                            <div class="title">OTHER INFORMATION</div>
                            <div class="flex wrap jsb" style="justify-content: start">
                                <div class="flex wrap jsb" v-if="type == 2">
                                    <el-form-item :label="$t('details.txgs') + ':'" prop="picFormat"
                                        style="width: 100%">
                                        <span v-html="detail.picFormat" dd="请输入图像格式"></span>
                                    </el-form-item>
                                </div>
                                <!-- <el-popover placement="top" :title="$t('details.pictureName')" :width="width * 0.93" trigger="hover"
                    :content="detail.pictureName">
                    <el-form-item slot="reference" :label="$t('details.pictureName') + ':'" prop="pictureName"
                      style="width: 93%; word-break: break-all">
                      <span v-html="detail.pictureName" dd="请输入图文件名"></span>
                    </el-form-item>
                  </el-popover> -->

                                <el-form-item :label="$t('details.provider') + ':'" prop="provider" style="width: 40%">
                                    <span v-html="detail.provider" dd="请输入贡献者"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.input') + ':'" prop="input" style="width: 50%"
                                    label-width="170px">
                                    <span v-html="detail.input" dd="请输入录入员"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.checkA') + ':'" prop="checkA" style="width: 40%">
                                    <span v-html="detail.checkA" dd="请输入校对员"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.deviceNameAndType') + ':'" prop="pictureName"
                                    style="width: 50%" label-width="170px">
                                    <span v-html="detail.deviceNameAndType" dd="请输入设备名称与型号"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.notes') + ':'" prop="notes" style="width: 40%">
                                    <span v-html="detail.notesEn" dd="请输入内容"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.dpi') + ':'" prop="dpi" v-if="type == 2"
                                    style="width: 40%">
                                    <span v-html="detail.dpi" dd="请输入分辨率"></span>
                                </el-form-item>
                                <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords" v-if="type == 2"
                                    style="width: 40%">
                                    <span v-html="detail.keyWords" dd="请输入关键词"></span>
                                </el-form-item>

                                <el-form-item :label="$t('details.description') + ':'" prop="description"
                                    style="width: 50%">
                                    <span v-html="detail.descriptionEn" dd="请输入描述-英文"></span>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </el-form>

                <comment :topicId="$route.query.id" :type="3"></comment>
            </div>

            <div class="info flex">
                <!-- <div class="code">
                    <div id="qrcode"></div>
                    <span v-if="$i18n.locale != 'en-us'">手机扫描查看分享</span>
                    <span v-else>Scan and share on your phone</span>
                </div> -->
                <div class="left">
                    <el-card>
                        <!-- <InfoDetail :detail="detail" :type="2"></InfoDetail> -->
                        <!-- <div class="title">文献系统推荐</div> -->
                        <div class="title">
                            <span>{{ $t('details.ReferenceRecommend') }}</span>
                        </div>
                        <references v-if="detail && detail.trivialNameEn" :name="detail && detail.trivialNameEn"
                            style="height: 269px; overflow: scroll">
                        </references>
                    </el-card>
                </div>
                <div class="right">
                    <el-card>
                        <div class="gbdb">
                            <div class="title">
                                GBDB
                                <span v-if="$i18n.locale != 'en-us'">推荐 </span>
                                <span v-else>recommend</span>
                            </div>
                            <GBDB v-if="detail && detail.trivialNameEn" :name="detail && detail.trivialNameEn"
                                :first="detail.genusEn" :second="detail.speciesEn"></GBDB>
                        </div>
                    </el-card>
                </div>

                <div class="location" ref="location">
                    <div class="text">
                        <div class="List">
                            <a v-for="(item, index) in textList" :key="item.text" @click="select(index)"
                                :href="'#d' + index" :class="active >= index ? 'active' : ''">
                                {{ item.text }}</a>
                        </div>

                        <div style="height: 150px">
                            <el-steps direction="vertical" :active="active">
                                <el-step v-for="(item, index) in textList" :key="item.text" @click="select(index)"
                                    :title="$i18n.locale != 'en-us' ? item.text : item.textEN" style="width: 130%">
                                </el-step>
                            </el-steps>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ImageApi from '@/api/image/index';
import commonApi from '@/api/common/index';
import userApi from '@/api/user/index'
import { Viewer } from "photo-sphere-viewer";
import QRCode from 'qrcodejs2';
import MyTable from '../../components/table/index.vue';
import { GeoTimeLine, GeoTimeScale } from '@zjugis/geo-timeline';
import {
    Message,
    MessageBox
} from 'element-ui'
export default {
    data() {
        return {
            hope: null,
            hope1: null,
            activeName: 'second',
            all: [],
            allEn: [],
            comment: '',
            detail: {},
            allList: [],
            width: 0,
            page: 1,
            infoAct: 0,
            getflag: false,
            type: 1,
            active: 0,
            id: null,
            textList: [
                {
                    text: '全景图',
                    textEN: 'PANORAMA',
                },
                {
                    text: '分类',
                    textEN: 'TAXONOMY',
                },
                {
                    text: '时代',
                    textEN: 'LITHOSTRATIGRAPHY AGE',
                },
                {
                    text: '地理',
                    textEN: 'LOCATION',
                },
                {
                    text: '标本',
                    textEN: 'FOSSIL SPECIMEN INFO',
                },
                {
                    text: '其他',
                    textEN: 'OTHER INFORMATION',
                },
            ],
            paleozoicList: [
                {
                    name: 'Cambrian',
                    color: '#7FA056',
                    width: '20%',
                    font: true,
                },
                {
                    name: 'O',
                    color: '#009270',
                    width: '15%',
                    font: true,
                },
                {
                    name: 'Silurian',
                    color: '#B3E1B6',
                    width: '15%',
                },
                {
                    name: 'Devonian',
                    color: '#CB8C37',
                    width: '15%',
                },
                {
                    name: 'C',
                    color: '#67A599',
                    width: '15%',
                    font: true,
                },
                {
                    name: 'Permian',
                    color: '#F04028',
                    width: '20%',
                },
            ],
            mesozoicList: [
                {
                    name: 'Triassic',
                    color: '#812B92',
                    width: '25%',
                    font: true,
                },
                {
                    name: 'Jurassic',
                    color: '#34B2C9',
                    width: '35%',
                    font: true,
                },
                {
                    name: 'Cretaceous',
                    color: '#7FC64E',
                    width: '40%',
                },
            ],
            cenozoicList: [
                {
                    name: 'Paleogene',
                    color: '#FD9A52',
                    width: '35%',
                },
                {
                    name: 'Neogene',
                    color: '#FFE619',
                    width: '35%',
                },
                {
                    name: 'Q',
                    color: '#F9F97F',
                    width: '30%',
                },
            ],
            PSV: "",

        };
    },
    components: {
        MyTable,
    },
    methods: {
        handleClick(tab, event) {
        },
        changeSub() {
            if (this.infoAct == 0) {
                return;
            }
            this.infoAct--;
        },
        // // 收藏
        // async delFavorite() {
        //   let { data } = await userApi.delFavorite(this.$route.query.id)
        // },
        changeAdd() {
            if (this.infoAct == this.detail.panoramaUrl.split('&').length - 1) {
                this.goInfo(this.allList[this.allAct + 1]);
                return;
            }
            this.infoAct++;
        },
        async init(id) {
            this.page = 1;
            const { data: res } = await ImageApi.getPanoramaInfo(id);
            this.all = [];
            this.allEn = [];
            this.detail = res;
            this.viewer = "";
            this.PSV = null;
            setTimeout(() => {
                if (!this.viewer) {
                    this.PSV = new Viewer({
                        container: document.querySelector("#viewer"),
                        panorama: this.detail && this.detail.panoramaUrl,
                        size: {
                            width: "800px",
                            height: "760px",
                        },
                        navbar: ["autorotate"],
                    });
                }
            }, 500);
            let arr = [
                this.detail.trivialName,
                this.detail.trivialNameEn,
                this.detail.phylum,
                this.detail.phylumEn,
                this.detail.classA,
                this.detail.classEn,
                this.detail.subclass,
                this.detail.subclassEn,
                this.detail.infraclass,
                this.detail.infraclassEn,
                this.detail.superorder,
                this.detail.superorderEn,
                this.detail.orderEn,
                this.detail.orderA,
                this.detail.suborder,
                this.detail.suborderEn,
                this.detail.infraorder,
                this.detail.infraorderEn,
                this.detail.superfamily,
                this.detail.superfamilyEn,
                this.detail.family,
                this.detail.familyEn,
                this.detail.subfamily,
                this.detail.subfamilyEn,
                this.detail.genus,
                this.detail.genusEn,
                this.detail.species,
                this.detail.speciesEn,
            ];
            arr = arr.filter((n) => n);
            const { data: res1 } = await commonApi.recommendSearch({
                category: arr,
                range: 'panorama',
                pageNum: this.page,
                pageSize: 6,
            });
            this.allList = res1.rows;
            this.allAct = this.allList.findIndex((el) => {
                return this.$route.query.id == el.topicId;
            });
        },
        async getmore() {
            let b = document.querySelector('.more')
            this.getflag = true;
            let arr = [
                this.detail.trivialName,
                this.detail.trivialNameEn,
                this.detail.phylum,
                this.detail.phylumEn,
                this.detail.classA,
                this.detail.classEn,
                this.detail.subclass,
                this.detail.subclassEn,
                this.detail.infraclass,
                this.detail.infraclassEn,
                this.detail.superorder,
                this.detail.superorderEn,
                this.detail.orderEn,
                this.detail.orderA,
                this.detail.suborder,
                this.detail.suborderEn,
                this.detail.infraorder,
                this.detail.infraorderEn,
                this.detail.superfamily,
                this.detail.superfamilyEn,
                this.detail.family,
                this.detail.familyEn,
                this.detail.subfamily,
                this.detail.subfamilyEn,
                this.detail.genus,
                this.detail.genusEn,
                this.detail.species,
                this.detail.speciesEn,
            ];
            arr = arr.filter((n) => n);
            this.page++
            const { data: res1 } = await commonApi.recommendSearch({
                category: arr,
                range: 'panorama',
                pageNum: this.page,
                pageSize: 6,
            });
            if (res1.rows.length == 0) {
                b.style.background = '#f2eeee'
                b.style.color = '#4e4e4e'
                Message({
                    message: "暂时没有更多了",
                    type: 'error'
                })
                this.getflag = false
            }
            this.allList = [...this.allList, ...res1.rows];
            this.getflag = false
        },
        goamap(x, y) {
            window.open(`https://ditu.amap.com/regeo?lng=${x}&lat=${y}`)

        },
        goInfo(item) {
            this.$router.push({
                path: 'panoramaInfo',
                query: {
                    id: item.topicId,
                },
            });
        },
        select(index) {

            this.active = index;
        },
        // 颜色处理
        initcolor(str) {
            let colorarr = str.split("/")
            return `rgb(${colorarr[0]}, ${colorarr[1]}, ${colorarr[2]})`
        }
    },

    mounted() {
        this.width = document.body.scrollWidth * 0.73 * 0.65 * 0.93
        // 
        window.onscroll = () => {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= 0 && scrollTop < 550) {
                this.active = 0
            } else if (scrollTop >= 550 && scrollTop < 810) {
                this.active = 1
            } else if (scrollTop >= 810 && scrollTop < 950) {
                this.active = 2
            } else if (scrollTop >= 950 && scrollTop < 1102) {
                this.active = 3
            } else if (scrollTop >= 1102 && scrollTop < 1202) {
                this.active = 4
            } else if (scrollTop >= 1202) {
                this.active = 5
            }

            if (scrollTop > 1055) {
                this.$refs.location.style.position = 'fixed';
                this.$refs.location.style.width = '23%'
                this.$refs.location.style.top = '65px';
            } else {
                this.$refs.location.style.position = 'relative';
                this.$refs.location.style.width = '100%'
                this.$refs.location.style.top = '0px';
            }
        }
        this.init(this.$route.query.id);
        const intervals = require('./are.json');

        // this.hope = new GeoTimeLine('#geoTimeLineContainer', intervals);
        // this.hope1 = new GeoTimeScale('#geoTimeLineContainer', intervals, {
        //   // simplify: false,
        //   // height: 120,
        //   Cambrian: "Permian",
        // });
        this.$nextTick(() => {
            this.qrcode = new QRCode('qrcode', {
                width: 70,
                height: 70, // 高度
                text: "默认内容", // 二维码内容
                // colorDark: "#23420f",
            });

            // this.hope1.stage = this.d
        });
    },
    watch: {
        $route(val) {
            if (val.query.id) {
                this.init(this.$route.query.id);
                this.infoAct = 0;
            }
        },
        detail: {
            handler(val) {
                this.id = val.id
                // this.hope1.stage = val.age
                if (val.trivialName) {
                    this.all.push({
                        title: 'Broadcategory',
                        content: val.trivialName,
                    });
                }
                if (val.trivialNameEn) {
                    this.allEn.push({
                        title: 'Broadcategory',
                        content: val.trivialNameEn,
                    });
                }

                if (val.phylum) {
                    this.all.push({
                        title: 'phylum',
                        content: val.phylum,
                    });
                }
                if (val.phylumEn) {
                    this.allEn.push({
                        title: 'phylum',
                        content: val.phylumEn,
                    });
                }

                if (val.classA) {
                    this.all.push({
                        title: 'classA',
                        content: val.classA,
                    });
                }
                if (val.classEn) {
                    this.allEn.push({
                        title: 'classA',
                        content: val.classEn,
                    });
                }

                if (val.subclass) {
                    this.all.push({
                        title: 'subclass',
                        content: val.subclass,
                    });
                }
                if (val.subclassEn) {
                    this.allEn.push({
                        title: 'subclass',
                        content: val.subclassEn,
                    });
                }

                if (val.infraclass) {
                    this.all.push({
                        title: 'infraclass',
                        content: val.infraclass,
                    });
                }
                if (val.infraclassEn) {
                    this.allEn.push({
                        title: 'infraclass',
                        content: val.infraclassEn,
                    });
                }

                if (val.superorder) {
                    this.all.push({
                        title: 'superorder',
                        content: val.superorder,
                    });
                }
                if (val.superorderEn) {
                    this.allEn.push({
                        title: 'superorder',
                        content: val.superorderEn,
                    });
                }

                if (val.orderA) {
                    this.all.push({
                        title: 'orderA',
                        content: val.orderA,
                    });
                }
                if (val.orderEn) {
                    this.allEn.push({
                        title: 'orderA',
                        content: val.orderEn,
                    });
                }

                if (val.suborder) {
                    this.all.push({
                        title: 'suborder',
                        content: val.suborder,
                    });
                }
                if (val.suborderEn) {
                    this.allEn.push({
                        title: 'suborder',
                        content: val.suborderEn,
                    });
                }

                if (val.infraorder) {
                    this.all.push({
                        title: 'infraorder',
                        content: val.infraorder,
                    });
                }
                if (val.infraorderEn) {
                    this.allEn.push({
                        title: 'infraorder',
                        content: val.infraorderEn,
                    });
                }

                if (val.superfamily) {
                    this.all.push({
                        title: 'superfamily',
                        content: val.superfamily,
                    });
                }
                if (val.superfamilyEn) {
                    this.allEn.push({
                        title: 'superfamily',
                        content: val.superfamilyEn,
                    });
                }

                if (val.family) {
                    this.all.push({
                        title: 'family',
                        content: val.family,
                    });
                }
                if (val.familyEn) {
                    this.allEn.push({
                        title: 'family',
                        content: val.familyEn,
                    });
                }

                if (val.subfamily) {
                    this.all.push({
                        title: 'subfamily',
                        content: val.subfamily,
                    });
                }
                if (val.subfamilyEn) {
                    this.allEn.push({
                        title: 'subfamily',
                        content: val.subfamilyEn,
                    });
                }

                if (val.genus) {
                    this.all.push({
                        title: 'genus',
                        content: val.genus,
                    });
                }
                if (val.genusEn) {
                    this.allEn.push({
                        title: 'genus',
                        content: val.genusEn,
                    });
                }

                if (val.species) {
                    this.all.push({
                        title: 'species',
                        content: val.species,
                    });
                }
                if (val.speciesEn) {
                    this.allEn.push({
                        title: 'species',
                        content: val.speciesEn,
                    });
                }
            },
        },
    },
};
</script>
  
<style scoped lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}

.main {
    width: 73%;
}

.form {
    display: block;

    .el-form-item {
        display: flex;
        font-size: 16px !important;

        width: 30%;
        margin-bottom: 0px !important;

        ::v-deep .el-form-item__label {
            text-align: left;
            font-weight: 500;
        }

        ::v-deep .el-form-item__content {
            font-size: 15px !important;
            color: #333333 !important;
        }
    }

    ::v-deep input {
        border: none;
    }
}

.home {
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-top: 20px;
    height: auto !important;
    padding-bottom: 50px;
    background-color: #f8f8f8;

    .show {
        height: 618px;



        .left {
            width: 66.5%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            overflow: hidden;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            border-radius: 4px;
            background-color: white;

            .lImg {

                position: absolute;
                left: 0;
                transform: translateY(-50%);
                cursor: pointer;
                display: none;
            }

            .rImg,
            .lImg {
                background-color: #ffffff;
                height: 520px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 4%;
                top: 46.2%;

                &:hover {
                    background-color: #E6E6E6;
                }

                i {
                    font-size: 30px;
                    font-weight: 800;
                    color: #9195A3;
                }
            }

            .rImg {
                cursor: pointer;
                position: absolute;
                transform: translateY(-50%);
                right: 0;
            }

            .el-card {
                width: 92%;
                height: 100%;
                box-sizing: border-box;
                box-shadow: none;
                border-radius: 0;
                border: 0;

                ::v-deep .el-card__body {
                    padding: 20px 0;

                }

                .img {
                    width: 100%;
                    margin: 5px auto;
                    height: 520px;
                    overflow: hidden;

                    div {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: contain;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: contain;
                    }
                }

                .left-footer {
                    width: 73%;
                    margin: 20px auto;

                    span {
                        font-size: 12px;
                    }

                    .footer-msg {
                        display: flex;
                        justify-content: right;

                        div {
                            &:last-child {
                                margin-left: 20px;
                            }

                            span {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .right {
            width: 31.5%;
            position: relative;

            .title {
                box-sizing: border-box;
                position: absolute;
                width: 98%;
                background-color: white;
                z-index: 10;
                top: 0;
                left: 1%;
                height: 50px;
            }

            .el-card {
                width: 100%;
                height: 90%;

                ::v-deep .el-card__body {
                    padding: 0 !important;
                    position: relative;
                }

                overflow-y: scroll;



                .infoList {
                    padding: 20px;
                    box-sizing: border-box;
                    width: 100%;
                    padding-top: 35px;

                    div {
                        width: 48%;
                        height: 170px;
                        margin-top: 20px;
                        border-radius: 8px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        flex-shrink: 0;
                        margin-bottom: 5px;
                        border-radius: 8px;
                    }

                    .imgAct {
                        border: 2px #1890ff solid;
                    }
                }
            }

            .more {
                height: 8%;
                width: 100%;
                margin-top: 2.5%;
                background: #1890ff;
                color: white;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: 0;

                p {
                    text-align: center;
                    // color: #4e4e4e;
                    font-size: 15px;
                }
            }
        }
    }

    .all {
        width: 73%;
        justify-content: space-between;
        margin: 0 auto;
    }

    .info {
        width: 31.5%;
        box-sizing: border-box;
        flex-flow: column;

        .code {
            width: 100%;
            height: 110px;
            // padding: 20px;
            background: #ffffff;
            border-radius: 12px;
            border: 1px solid #f2f2f2;
            margin-top: 20px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            align-items: baseline;
            position: relative;

            span {
                font-size: 14px;
                font-weight: 800;
                margin-left: 17px;
                position: absolute;
                width: 60%;
                bottom: 20px;
                left: 90px;
            }
        }

        .left {
            margin-top: 20px;
            // height: 100%;

            .el-card {
                width: 100%;
                height: 100%;

                .gbdb {
                    // width: 320px;
                    // height: 300px;
                    min-height: 50px;
                    max-height: 300px;
                    overflow-y: scroll;
                }

                .title {
                    font-size: 16px;
                    font-weight: 800;
                    color: #333333;
                }
            }
        }

        .right {
            // height: 100%;
            margin-top: 20px;

            .el-card {
                width: 100%;
                height: 100%;

                .gbdb {
                    // width: 320px;
                    min-height: 50px;
                    max-height: 300px;
                    overflow-y: scroll;

                    .title {
                        font-size: 16px;
                        font-weight: 800;
                        color: #333333;
                    }

                    margin: 0 auto;
                }

                .attr-box {
                    width: 320px;
                    height: 308px;
                    background: #f1f1f1;
                    border-radius: 4px;
                    margin: 0 auto;
                }
            }
        }

        .location {
            background: #ffffff;
            border-radius: 12px;
            border: 1px solid #f2f2f2;
            margin-top: 20px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            box-sizing: border-box;
            padding: 25px;
            padding-left: 10px;
            position: relative;

            .current {
                position: absolute;
                line-height: 1;
                transform: rotate(90deg);
                top: 118px;
                left: -20%;
                width: 63%;
                height: 4px;

                ::v-deep .el-progress__text {
                    display: none;
                }

                ::v-deep .el-progress-bar__outer {
                    height: 2px !important;
                }

                ::v-deep .el-progress-bar__inner {
                    background-color: black;
                }
            }

            .text {
                .List {
                    width: 100%;
                    height: 82%;
                    position: absolute;
                    z-index: 99;
                    opacity: 0;
                }

                a {
                    height: 24px;
                    width: 100%;
                    display: block;
                    margin-top: 3px;
                    font-size: 16px;
                    font-weight: 800;
                    margin-left: 20px;
                    position: relative;
                    color: #d8d8d8;
                    cursor: pointer;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                ::v-deep .el-step__head {
                    top: 16px;

                    .el-step__icon {
                        height: 6px;
                        width: 6px;
                        left: 9px;
                        top: -16px;
                        border-color: #d8d8d8;
                        background-color: #d8d8d8;

                        .el-step__icon-inner {
                            display: none;
                        }
                    }
                }

                ::v-deep .is-finish {
                    color: black;
                    border-color: black;

                    .el-step__line {
                        background-color: black;
                    }

                    .el-step__icon {
                        border-color: black;
                        background-color: black;
                    }
                }

                ::v-deep .el-step__main {
                    padding-left: 5px;

                    .el-step__title {
                        cursor: pointer;
                    }

                    .is-finish {
                        color: black;
                        font-weight: 800;
                    }
                }

                ::v-deep .is-process {
                    .el-step__icon {
                        background-color: black;
                        border-color: black;
                    }
                }

                .active {
                    color: black !important;

                    &::after {
                        background-color: black;
                    }
                }
            }
        }
    }

    .msg {
        width: 66.5%;
        margin: 0;

        ::v-deep .el-textarea {
            height: 43px;

            .el-textarea__inner {
                height: 43px;
                border-radius: 0;
            }
        }

        &-list {
            .single {
                background: #ffffff;
                border-radius: 12px;
                border: 1px solid #f2f2f2;
                margin-top: 20px;
                box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
                box-sizing: border-box;
                position: relative;
                // padding: 13px 24px;

                a {
                    color: #1890ff !important;
                    text-decoration: revert !important;
                }

                .agelist {
                    width: 100%;
                    margin-top: 24px;
                    margin-bottom: 12px;
                    justify-content: space-evenly;

                    div {

                        height: 50px;
                        padding: 0 20px;
                        background: red;
                        min-width: 80px;
                    }
                }

                .title {
                    background-color: #90dcfe;
                    color: #333333;
                    font-size: 18px;
                    font-weight: 800;
                    height: 46px;
                    line-height: 46px;
                    border-top-right-radius: 12px;
                    border-top-left-radius: 12px;
                    padding-left: 24px;
                }

                span {
                    width: 100%;
                }

                ::v-deep .el-form-item {
                    // padding-left: 24px;
                    margin-left: 24px;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 1;

                    // &:hover {
                    //   -webkit-line-clamp: 2;
                    // }
                }
            }

            ::v-deep .el-form-item__label {
                font-size: 15px;
                color: #333333;
            }

            ::v-deep .div__item {
                padding-left: 25px !important;
            }

            ::v-deep .div__nav-scroll {
                border-radius: 12px;
            }

            ::v-deep .div {
                padding: 0 25px 17px 25px !important;
                // padding-top: 17px;
            }
        }
    }

    .comment {
        box-sizing: border-box;
        margin: 0 auto;
        margin-top: 23px;
        background: #ffffff;
        border-radius: 12px;
        border: 1px solid #f2f2f2;
        padding: 14px 20px 40px 20px;

        &-title {
            margin-bottom: 20px;
            font-weight: 800;
        }

        &-input {
            width: 90%;
            display: flex;

            textarea {
                box-sizing: border-box;
                width: 85%;
                height: 43px;
                background-color: #f8f8f8;
                border-radius: 0;
                border: 1px solid #b9b9b9;
                padding: 10px;
            }

            .el-button {
                margin-left: 5%;
                // width: 10%;
                border-radius: 0;
                width: 65px;
                height: 43px;
                background: #4fa0f8;
                opacity: 0.84;
            }
        }
    }
}

.amap-wrapper {
    width: 33%;
    height: 70%;
    right: 15%;
    top: 24%;
    position: absolute;
}
</style>
  